@import '../../styles/common.css';

.disclaimer-container {
	background-color: white;
	padding: 4rem 0 2rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.disclaimer-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: left;
	text-align:justify;
	margin-bottom: 24px;
	padding: 24px;
	width: 75%;
	font-size:medium;
}

.disclaimer-content > p {
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
			'Lucida Sans', Arial, sans-serif;
}

h1 {
	text-align: center;
}